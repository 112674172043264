.footer-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: var(--dark-bg);
    color: var(--white-fonts);
    box-sizing: border-box;
    bottom: 0;
    z-index: 9;
}

.footer-container {
    max-width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    padding: 42px 0;
}

.footer-logo {
    background-image: url(../../LogoFullWhite.png);
    background-repeat: no-repeat;
    display: inline-block;
    height: 114px;
    width: 114px;
    background-size: 114px;
    background-position: top 0 center;
    flex: 1.5;
    color: var(--footer-white);
    font-size: 10px;
    padding-top: 106px;
    text-align: center;
    box-sizing: border-box;
}

.footer-col-wrapper {
    display: flex;
    flex: 5;
}

.footer-col {
    flex: 1;
}

.footer-col-title {
    display: block;
    font-family: 'poppins-light';
    font-weight: bold;
    color: var(--footer-white);
    font-size: 15px;
    margin-bottom: 9px;
}

.footer-col-content {}

.footer-col-row {
    display: block;
    font-family: 'poppins-regular';
    font-size: 12px;
    line-height: 23px;
    color: var(--footer-white);
    letter-spacing: .1px;
}

.footer-col-row svg {
    margin-right: 7px;
    position: relative;
    top: 2px;
    font-size: 13px;
}

.footer-col-row.link {
    cursor: pointer;
}

@media only screen and (max-width: 835px) {
    .footer-container {
        display: block;
        padding: 15px 19px;
    }

    .footer-logo {
        position: absolute;
        bottom: 22px;
        right: 14px;
        background-size: 79px;
        padding-top: 69px;
        height: 88px;
        width: 188px;
    }

    .footer-col-wrapper {
        display: inline-block;
        flex: none;
        width: 100%;
    }

    .footer-col {
        flex: none;
        display: block;
        width: 100%;
        margin-bottom: 29px;
    }

    .footer-col-title {
        color: var(--white-fonts);
    }
}