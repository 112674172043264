.contact-wrapper {
    display: flex;
    margin-top: 24px;
    padding: 0 45px;
    box-sizing: border-box;
}

.contact-from {
    flex: 2 1;
    margin-right: 49px;
}

.contact-info {
    flex: 2 1;
    margin-top: 46px;
}


.contact-from input,
.contact-from textarea {
    display: block;
    width: 100%;
    outline: none;
    margin: 16px 0;
    border: var(--subtle-border);
    padding: 7px 10px;
    border-radius: 2px;
    box-sizing: border-box;
}

.contact-from textarea {
    height: 150px;
    resize: none;
}

.contact-title {
    display: block;
    font-weight: bold;
    margin-bottom: 7px;
}

.contact-info svg {
    color: var(--orange);
    margin-right: 5px;
    position: relative;
    top: 3px;
}

.contact-row {
    display: block;
    margin-top: 7px;
    line-height: 29px;
    white-space: nowrap;
    border-bottom: var(--subtle-border);
    padding-bottom: 9px;
    font-size: 15px;
    font-family: 'poppins-light';
    letter-spacing: .8px;
    max-width: 57%;
}

.contact-from button {
    background-color: var(--orange);
    border: none;
    border-radius: 2px;
    padding: 9px 36px;
    margin-bottom: 16px;
    cursor: pointer;
    color: #fff;
}

.contact-banner-van {
    background-image: url('../../images/van.png');
    display: inline-block;
    height: 100%;
    width: 46vw;
    position: absolute;
    top: 52px;
    right: 0px;
    z-index: 1;
    background-size: 48vw;
    background-repeat: no-repeat;
    animation: van-animate 1500ms ease-in-out 50ms;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes van-animate {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    78% {
        opacity: 1;
        transform: translateX(-1%);
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@media only screen and (max-width: 835px) {
    .contact-row {
        white-space: normal;
    }
}

@media only screen and (max-width: 1765px) {
    .contact-banner-van {
        width: 46vw;
        background-size: 48vw;
        top: 52px;
        right: 0px;
    }
}

@media only screen and (max-width: 835px) {
    .contact-wrapper {
        display: block;
        width: 100%;
        padding: 12px 11px;
        margin: 0;
        position: relative;
        height: 595px;
        overflow: hidden;
        box-sizing: border-box;
    }

    .contact-from {
        float: none;
        margin: 0;
        position: absolute;
        bottom: 13px;
        width: 94vw;
        border-top: 1px solid var(--orange);
        padding-top: 19px;
    }

    .contact-from button {
        margin: 0 auto;
        display: block;
    }

    .contact-info {
        position: absolute;
        top: 19px;
        width: 94vw;
        margin-top: 0;
    }

    .contact-title {
        font-size: 13px;
        text-align: center;
    }

    .contact-row {
        font-size: 12px;
        max-width: none;
        line-height: 19px;
    }
}