.page-container {
    min-height: 100vh;
    margin-top: 62px;
}

.page-content-wrapper {
    max-width: 1170px;
    margin: 0 auto;
    transition: ease all .5s;
}

.page-content {
    animation: page-content-animate 1500ms ease-in-out 200ms;
    animation-fill-mode: forwards;
    opacity: 0;
}

.page-banner {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    height: 573px;
    overflow: hidden;
    transition: ease all .5s;
}

.page-banner.blue {
    background-color: var(--blue-bg);
    background-image: linear-gradient(45deg, var(--blue-bg) 46%, #f1f2f7 100%);
}

.page-banner.orange {
    background-color: var(--orange);
    background-image: linear-gradient(45deg, var(--orange) 46%, #f1f2f7 100%);
}

.page-banner-image-wrapper {
    float: right;
    height: 495px;
    width: 53%;
    position: relative;
    right: 18px;
    top: 47px;
    max-width: 1009px;
    overflow: hidden;
    border-radius: 14px;
    animation: image-wrapper-h1-animate 900ms ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
    transition: ease all .5s;
}

.page-banner-image {
    height: 100%;
    width: 100%;
    transform: skewX(48deg);
    position: relative;
    right: -291px;
    overflow: hidden;
    border-radius: 14px;
}

.page-banner-image::before {
    content: '';
    position: absolute;
    transform: skewX(-48deg);
    height: 200%;
    width: 100%;
    right: 558px;
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
}

.banner-about::before {
    background-image: url('../images/aboutBanner3.jpg');
}

.banner-maintenance::before {
    background-image: url('../images/cleaning2.jpg');
}

.banner-postConstruction::before {
    background-image: url('../images/carousel2.jpg');
    background-position: top -192px center;
}

.banner-otherServices::before {
    background-image: url('../images/otherServices.jpg');
}

.page-banner-verbiage {
    position: relative;
    top: 214px;
    margin: 0 auto;
    display: block;
    max-width: 1170px;
    padding: 0 77px;
    box-sizing: border-box;
    transition: ease all .5s;
}

.page-banner-h1 {
    display: block;
    color: var(--white-fonts);
    font-size: 37px;
    font-weight: bold;
    letter-spacing: 1.5px;
    animation: h1-animate 900ms ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
    position: absolute;
    max-width: 59%;
}

.page-banner-h2 {
    display: block;
    color: var(--white-fonts);
    line-height: 1.7;
    letter-spacing: .48px;
    margin-top: 14px;
    animation: h1-animate 1500ms ease-in-out 110ms;
    animation-fill-mode: forwards;
    opacity: 0;
    max-width: 42%;
    position: absolute;
    top: 57px;
}

@keyframes h1-animate {
    0% {
        transform: translateY(15%);
        opacity: 0;
    }

    80% {
        transform: translateY(-1%);
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes image-wrapper-h1-animate {
    0% {
        transform: translateX(3%);
        opacity: 0;
    }

    78% {
        transform: translateX(-.1%);
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }

}

@keyframes page-content-animate {
    0% {
        transform: translateY(-1%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media only screen and (max-width: 1565px) {
    .page-banner {
        height: 428px;
    }

    .page-banner-verbiage {
        max-width: 1730px;
        top: 127px;
    }

    .page-banner-image-wrapper {
        height: 364px;
        width: 67%;
        right: -47px;
        top: 40px;
    }

    .page-content-wrapper {
        max-width: 1730px;
    }
}

@media only screen and (max-width: 1085px) {
    .page-banner {
        height: 286px;
    }

    .page-banner-verbiage {
        max-width: 1730px;
        top: 63px;
    }

    .page-banner-h1 {
        font-size: 21px;
    }

    .page-banner-h2 {
        top: 27px;
        font-size: 14px;
    }

    .page-banner-image-wrapper {
        height: 223px;
        width: 84%;
        right: -128px;
        top: 40px;
    }

    .page-content-wrapper {
        max-width: 1730px;
    }

    .page-banner-image::before {
        background-position: top 0 center;
    }
}


@media only screen and (min-width: 3519px) {
    .page-banner-image-wrapper {
        right: 29vw;
    }
}

@media only screen and (max-width: 835px) {

    .page-banner.blue,
    .page-banner.orange {
        background-color: transparent;
        background-image: none;
        height: 190px;
    }

    .page-banner-image-wrapper {
        position: absolute;
        right: 0;
        top: auto;
        left: 0;
        display: block;
        width: 100%;
        float: none;
        border-radius: 0;
        height: 189px;
    }

    .page-banner-image {
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -10;
        transform: skewX(0deg);
        border-radius: 0;
    }

    .page-banner-image::before {
        right: auto;
        width: 100%;
        height: 190px;
        transform: skewX(0deg);
    }

    .page-banner-verbiage {
        position: relative;
        top: 0;
        margin: 0;
        padding: 0;
        display: block;
        box-sizing: border-box;
        text-align: center;
        width: 100%;
        z-index: 1;
    }

    .page-banner.blue .page-banner-verbiage::after,
    .page-banner.orange .page-banner-verbiage::after {
        content: '';
        position: absolute;
        right: 0;
        height: 190px;
        left: 0;
        opacity: .8;
        z-index: -10;
    }

    .page-banner.blue .page-banner-verbiage::after {
        background-image: linear-gradient(164deg, var(--blue-bg) 66%, #f1f2f7 100%);
    }

    .page-banner.orange .page-banner-verbiage::after {
        background-image: linear-gradient(164deg, var(--orange) 66%, #f1f2f7 100%);
    }

    .page-banner-h1 {
        font-family: 'poppins-light';
        font-size: 15px;
        max-width: none;
        width: 100%;
        top: 22px;
    }

    .page-banner-h2 {
        display: block;
        max-width: none;
        width: 100%;
        font-size: 12px;
        font-family: 'poppins-light';
        margin-top: 3px;
        top: 60px;
    }
}