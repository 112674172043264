.navbar-container {
    width: 100%;
    display: inline-block;
    background-color: transparent;
    line-height: 104px;
    position: fixed;
    top: 0;
    z-index: 10;
    animation: bar-animate 450ms ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;

}

.navbar-container.active {
    background-color: rgba(0, 105, 170, .9);
    background-color: rgba(255, 255, 255, .9);
    line-height: 62px;
    z-index: 5;
    transition: background-color 225ms linear;
    -moz-transition: background-color 225ms linear;
    -webkit-transition: background-color 225ms linear;
    -ms-transition: background-color 225ms linear;
    border-bottom: none;
    animation: bar-animate-active 450ms ease-in-out 110ms;
    animation-fill-mode: forwards;
    opacity: 0;
}

.navbar-wrapper {
    max-width: 1170px;
    display: block;
    margin: 0 auto;
    text-align: right;
    position: relative;
}

.navbar-logo {
    position: relative;
    float: left;
    line-height: normal;
    color: var(--white-fonts);
    text-decoration: none;
    cursor: pointer;
    top: 43px;
    left: 62px;
    z-index: 1;
    animation: logo-animate 450ms ease-in-out 110ms;
    opacity: 0;
    animation-fill-mode: forwards;
    ;
}

.navbar-container.active .navbar-logo {
    position: relative;
    float: left;
    line-height: normal;
    color: var(--white-fonts);
    color: var(--orange);
    text-decoration: none;
    cursor: pointer;
    top: 23px;
    left: 62px;
    font-size: 13px;
    animation: logo-animate-active 450ms ease-in-out 110ms;
    animation-fill-mode: forwards;
    opacity: 0;
}

.navbar-logo:after {
    content: '';
    background: url(../../logoWhite.png) no-repeat;
    position: absolute;
    display: block;
    height: 81px;
    width: 85px;
    background-size: 110px;
    top: -30px;
    left: -93px;
    background-position: top -10px left -12px;
}

.navbar-container.active .navbar-logo:after {
    content: '';
    background: url(../../LogoFull.png) no-repeat;
    position: absolute;
    display: block;
    height: 54px;
    width: 64px;
    background-size: 55px;
    top: -11px;
    left: -66px;
}

.navbar-link {
    color: var(--white-fonts);
    text-decoration: none;
    padding: 0 17px;
    font-family: poppins-light;
    position: relative;
    top: 5px;
    transition: ease all .5s;
}

.navbar-link.login{
    background-color: var(--orange);
    padding: 10px 16px !important;
    border-radius: 5px;
    color: var(--white-fonts) !important;
    font-size: 14px;
    margin-left: 39px;
}

.navbar-container.active .navbar-link {
    color: var(--verbiage-font);
    text-decoration: none;
    padding: 0 13px;
    font-family: poppins-light;
    font-size: 13px;
}

.navbar-active-animate {
    animation: links-animate 450ms ease-in-out 100ms;
    animation-fill-mode: forwards;
    opacity: 0;
}

@media only screen and (min-width: 835px) {
    .navbar-container.active .navbar-active-animate {
        animation: links-animate-active 450ms ease-in-out 110ms;
        animation-fill-mode: forwards;
        opacity: 0;

    }
}

@keyframes links-animate-active {
    0% {
        transform: translateY(4%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes logo-animate-active {
    0% {
        transform: translateY(-4%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes bar-animate-active {
    0% {
        transform: translateY(-9%);
        opacity: 0;
    }

    70% {
        transform: translateY(0);
        opacity: 1;
        border-bottom: none;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
        border-bottom: var(--subtle-border);
    }
}

@keyframes bar-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes links-animate {
    0% {
        transform: translatex(-1%);
        opacity: 0;
    }

    100% {
        transform: translatex(0);
        opacity: 1;
    }
}

@keyframes logo-animate {
    0% {
        transform: translatex(1%);
        opacity: 0;
    }

    100% {
        transform: translatex(0);
        opacity: 1;
    }
}

.navbar-mobile-toggle {
    display: none;
}

@media only screen and (max-width: 1317px) {
    .navbar-wrapper {
        max-width: 89%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1056px) {
    .navbar-link {
        font-size: 14px;
        padding: 0 8px;
    }
}

@media only screen and (max-width: 835px) {
    .navbar-mobile-wrapper {
        width: 100%;
        height: 62px;
        display: block;
        background-color: #fff;
        position: fixed;
        z-index: 1;
        /* top: 0; */
        border-bottom: var(--subtle-border);
        padding: 0 23px;
        box-sizing: border-box;
    }

    .navbar-mobile-toggle {
        display: inline-block;
        position: absolute;
        top: 14px;
        right: 13px;
        color: var(--verbiage-font);
        cursor: pointer;
        padding: 10px 18px;
    }

    .navbar-wrapper {
        width: 100%;
        margin: 0;
        max-width: 100%;
    }

    .navbar-active-animate {
        display: none;
        position: absolute;
        top: 62px;
        width: 100%;
        line-height: normal;
        background-color: #fff;
        text-align: left;
        animation: nobile-nav-animate 340ms;
        opacity: 0;
        animation-fill-mode: forwards;
    }

    .toggle-on.navbar-active-animate {
        display: block;
    }

    .navbar-container .navbar-active-animate,
    .navbar-container.active .navbar-active-animate {
        animation-fill-mode: forwards;
        opacity: 1;
        border: none;
    }

    .navbar-container,
    .navbar-container.active {
        animation: none;
        opacity: 1;
    }

    .navbar-container .navbar-link,
    .navbar-container.active .navbar-link {
        display: block;
        line-height: 44px;
        border-bottom: var(--subtle-border);
        top: 0;
    }

    .navbar-link.login{
        background-color: var(--orange);
        padding: 5px 16px;
        border-radius: 0;
        color: var(--white-fonts) !important;
        text-align: center; 
        margin: 0;
    }

    .navbar-container .navbar-logo,
    .navbar-container.active .navbar-logo {
        position: relative;
        float: left;
        line-height: normal;
        color: var(--orange);
        text-decoration: none;
        cursor: pointer;
        top: 23px;
        left: 62px;
        font-size: 13px;
        animation: logo-animate-active 450ms ease-in-out 110ms;
        animation-fill-mode: forwards;
    }

    .navbar-container .navbar-link,
    .navbar-container.active .navbar-link {
        color: var(--verbiage-font);
        text-decoration: none;
        padding: 0 13px;
        font-family: poppins-light;
        font-size: 13px;
    }

    .navbar-container .navbar-logo:after,
    .navbar-container.active .navbar-logo:after {
        content: '';
        background: url('../../LogoFull.png') no-repeat;
        position: absolute;
        display: block;
        height: 54px;
        width: 64px;
        background-size: 55px;
        top: -11px;
        left: -66px;
    }

    @keyframes nobile-nav-animate {
        0% {
            transform: translateY(-100%);
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}