@font-face {
  src: url(./fonts/Poppins/Poppins-Regular.ttf);
  font-family: poppins-regular;
}

@font-face {
  src: url(./fonts/Poppins/Poppins-Light.ttf);
  font-family: poppins-light;
}

@font-face {
  src: url(./fonts/Poppins/Poppins-Thin.ttf);
  font-family: poppins-thin;
}

@font-face {
  src: url(./fonts/Poppins/Poppins-ExtraBold.ttf);
  font-family: poppins-extraBold;
}

* {
  font-family: poppins-regular;
}

:root {
  --main-bg: rgb(241, 242, 247);
  --white-fonts: rgb(241, 242, 247);
  --verbiage-font: rgb(100, 100, 100);
  --footer-white: rgba(203, 214, 226, 1);
  --blue-bg: #0069aa;
  --orange: #eb6c24;
  --dark-bg: rgba(45, 62, 80, 1);
  --subtle-border: 1px solid rgb(221 221 221);

}

body {
  padding: 0;
  margin: 0;
  font-family: Poppins-Light;
}