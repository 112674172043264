.construction-card-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.construction-card {
    flex: 32% 1;
    margin: 40px 83px;
}

.construction-card-title {
    font-family: 'poppins-regular';
    font-weight: bold;
    color: #000;
    font-size: 19px;
    display: block;
    margin: 6px 0 26px 0;
    position: relative;

}

.construction-card-title::after {
    content: '';
    border-bottom: 4px solid var(--orange);
    height: 5px;
    width: 49px;
    position: absolute;
    left: 0;
    top: 31px;
}

.construction-card-verbiage {
    color: var(--verbiage-font);
    font-size: 13px;
    margin-bottom: 21px;
    display: block;
    line-height: 1.9;
    letter-spacing: .3px;
}

.construction-card-image {
    display: block;
    width: 100%;
    height: 441px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.renovation {
    background-image: url('../../images/renovationCard.jpg');
    background-position: top -187px center;
}

.post-constuction {
    background-image: url('../../images/postConstructionCard.jpg');
}


.mid-construction {
    background-image: url('../../images/midConstruction.jpg');
}

.inspection {
    background-image: url('../../images/inspection.jpg');
}

@media only screen and (max-width: 922px) {
    .construction-card-wrapper {
        display: block;
        padding: 9px 9px;
        height: 841px;
        width: 94%;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        border-bottom: 2px solid var(--orange);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 13px;
    }

    .construction-card {
        flex: none;
        margin: 0;
        height: 432px;
        overflow: hidden;
    }

    .construction-mobile-top {
        position: absolute;
        top: 25px;
        width: 95%;
        max-height: 421px;
    }

    .construction-mobile-bottom {
        position: absolute;
        bottom: 0;
        max-height: 412px;
    }

    .construction-card-image {
        display: block;
        width: 100%;
        height: 419px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 25px;
        box-shadow: 25px 25px 50px 0 white inset, -25px -25px 50px 0 white inset;
    }
}