.mp4-opacity {
    opacity: .6;
    top: 23vh;
    left: 18%;
    width: auto;
    max-width: 85%;
    animation: opacity-animation 1250ms ease-in-out 1500ms;
    animation-fill-mode: forwards;
    display: inline-block;
}

.mp4-opacity .carousel-line1 {
    font-size: 32px;
}

.mp4-opacity .carousel-line2 {
    line-height: 30px;
    font-size: 17px;
}

.mp4-opacity .carousel-line3 {
    visibility: hidden;
    opacity: 0;
    animation: line3-opacity-animation 1250ms ease-in-out 1000ms;
    animation-fill-mode: forwards;

}

@keyframes opacity-animation {
    0% {
        opacity: .6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes line3-opacity-animation {
    0% {
        visibility: hidden;
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
    }
}

@media only screen and (max-width: 1400px) {
    .mp4-opacity .carousel-line1 {
        font-size: 20px;
    }

    .mp4-opacity .carousel-line2,
    .mp4-opacity .carousel-line3 {
        font-size: 15px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 930px) {
    .mp4-opacity {
        left: 0;
        width: auto;
        max-width: initial;
        text-align: left;
    }

    .mp4-opacity .carousel-line2,
    .mp4-opacity .carousel-line3 {
        text-align: left;
    }
}


@media only screen and (max-width: 930px) {
    .mp4-opacity {
        top: 16vh;
        left: 0;
        width: auto;
        max-width: initial;
        text-align: center;
    }

    .mp4-opacity .carousel-line1 {
        font-size: 15px;
    }

    .mp4-opacity .carousel-line2 {
        margin-top: 26px;
        ;
    }

    .mp4-opacity .carousel-line2,
    .mp4-opacity .carousel-line3 {
        text-align: left;
        font-size: 13px;
        line-height: 22px;
    }

    .carousel-button {
        font-size: 14px;
    }
}