.carousel-wrapper {
    position: absolute;
    top: 30vh;
    width: 85%;
    left: 12%;
    text-align: left;
    transition: top .8s ease;
    margin: 0 auto;
}

.carousel-line1 {
    display: block;
    color: var(--white-fonts);
    font-family: 'poppins-regular';
    font-size: 51px;
    animation: line1-animate 800ms ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
    transition: top .8s ease;
}

.carousel-line2,
.carousel-line3 {
    display: block;
    color: var(--white-fonts);
    font-size: 18px;
    width: 711px;
    font-family: 'poppins-light';
}

.carousel-line2 {
    margin: 13px 0 21px 0;
    animation: line2-animate 1250ms ease-in-out 100ms;
    animation-fill-mode: forwards;
    opacity: 0;
    transition: top .8s ease;
}

.carousel-line3 {
    animation: line2-animate 1250ms ease-in-out 225ms;
    animation-fill-mode: forwards;
    opacity: 0;
    transition: top .8s ease;
}

.carousel-button {
    display: inline-block;
    color: var(--white-fonts);
    background-color: transparent;
    border: 2px solid var(--orange);
    padding: 7px 34px;
    cursor: pointer;
    margin-top: 31px;
    font-size: 18px;
    font-family: 'poppins-light';
    animation: button-animate 1500ms ease-in-out 250ms;
    vertical-align: top;
    border-radius: 2px;
    animation-fill-mode: forwards;
    opacity: 0;
}

.carousel-arrow {
    animation: carousel-arrow-animate 1500ms ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;

}

@keyframes button-animate {
    0% {
        transform: translateY(200%);
        opacity: 0;
    }

    80% {
        transform: translateY(-3%);
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes line1-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes line2-animate {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes line3-animate {
    0% {
        transform: translatex(-2%);
    }

    100% {
        transform: translatex(0);
    }
}


@keyframes carousel-arrow-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 930px) {
    .carousel-wrapper {
        top: 22vh;
        width: 100%;
        text-align: center;
        left: 0;
        padding: 0 24px;
        box-sizing: border-box;
    }

    .carousel-line1 {
        font-size: 37px;
    }

    .carousel-line2,
    .carousel-line3 {
        display: block;
        text-align: center;
        font-size: 16px;
        width: 100%;
    }

    .carousel-arrow {
        top: 77%
    }
}