.home-entrance {
    height: 87vh;
}

.home-bottom-banner {
    background-color: var(--blue-bg);
    line-height: 13vh;
    box-sizing: border-box;
    padding: 0 36px;
    font-family: poppins-thin !important;
    color: var(--white-fonts);
    font-size: 20px;
    border-top: 2px solid var(--orange);
}

.home-bottom-banne-wrapper {
    max-width: 1170px;
    margin: 0 auto;
}

.home-bottom-banner-text {
    display: inline-block;
    transition: ease all .5s;
}

.home-bottom-banner-button {
    display: inline-block;
    border: 1px solid var(--white-fonts);
    border-radius: 4px;
    line-height: normal;
    padding: 9px 22px;
    margin-left: 13px;
    cursor: pointer;
    transition: ease all .5s;
}

.home-peripherals {
    background-color: #fff;
    min-height: 225px;
}

.home-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.home-card {
    flex: 44%;
    margin: 20px 30px;
}

.home-card-title {
    font-family: 'poppins-regular';
    font-weight: bold;
    color: #000;
    font-size: 19px;
    display: block;
    margin: 6px 0;
}

.home-card-title svg {
    display: block;
    font-size: 38px;
    margin-bottom: 15px;
    color: var(--orange);
}

.home-card-verbiage {
    color: var(--verbiage-font);
    font-size: 13px;
}

.home-mid-section {
    background-color: #fff;
    padding: 12px 0 33px 0;
    box-sizing: border-box;
    transition: ease all .5s;
}

.home-mid-section-divider {
    background: url('../../images/mid-section.jpg');
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right center;
    height: 189px;
    background-size: contain;
    position: relative;
    z-index: 1;
    transition: ease all .5s;
}

.home-mid-section-divider::after {
    content: '';
    position: absolute;
    background-image: linear-gradient(62deg, var(--orange) 60%, transparent 100%);
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: ease all .5s;
}

.home-mid-section-veriage-container {
    position: relative;
    z-index: 2;
    max-width: 1170px;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    top: 24%;
    left: -13%;
    transition: ease all .5s;
}

.home-mid-section-veriage {
    color: var(--white-fonts);
    font-size: 20px;
    display: block;
    position: relative;
    transition: ease all .5s;
}


.home-mid-section-veriage:nth-child(2) {
    top: 11px;
    left: 73px;
}

.recent-cards {
    display: flex;
    flex-wrap: wrap;
    margin: 28px auto;
    justify-content: space-between;
    transition: ease all .5s;
}

.recent-card {
    flex: 23% 1;
    margin: 0 11px 23px 11px;
    text-align: center;
    position: relative;
    max-width: 23%;
    height: 301px;
    overflow: hidden;
    transition: ease all .5s;
}

.recent-card img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ease all .5s;
}

.recent-card-hover {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    transition: visibility 0s, opacity 0.5s linear;
}

.recent-card-hover::after {
    content: '';
    position: absolute;
    background-color: transparent;
    background-image: linear-gradient(0deg, var(--orange) 32%, transparent 100%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.recent-card:hover .recent-card-hover {
    visibility: visible;
    opacity: 1;
}

.recent-card-text-wrapper {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 19px;
    width: 100%;
    padding: 0 17px;
    box-sizing: border-box;
    max-height: 90px;
    overflow: hidden;
    transition: ease all .5s;
}

.recent-title {
    font-size: 15px;
    color: var(--white-fonts);
    display: block;
    text-align: center;
    font-family: 'poppins-light';
    font-weight: bold;
    transition: ease all .5s;
}

.recent-description {
    font-size: 13px;
    color: var(--white-fonts);
    display: block;
    text-align: center;
    font-family: 'poppins-light';
    margin-top: 7px;
    transition: ease all .5s;
}


@media only screen and (max-width: 1260px) {
    .home-bottom-banner {
        line-height: normal;
        padding: 1.5vh 11px;
        text-align: center;
    }


    .home-bottom-banner-text {
        font-size: 12px;
        padding-bottom: 13px;
    }

    .home-bottom-banner-button {
        font-size: 11px;
    }

    .home-mid-section-divider {
        height: auto;
        overflow: hidden;
        background-position: right 31% center;

    }

    .home-mid-section-divider::after {
        background-image: linear-gradient(90deg, var(--orange) 56%, transparent 64%, var(--orange) 78%);
    }

    .home-mid-section-veriage-container {
        padding: 1.5vh 11px;
        text-align: left;
        left: 16%;
    }

    .home-mid-section-veriage,
    .home-mid-section-veriage:nth-child(2) {
        font-size: 12px;
        top: 0;
        left: 4px;
    }
}

@media only screen and (max-width: 1156px) {
    .recent-cards {
        justify-content: space-between;
    }

    .recent-card {
        width: 28%;
    }
}

@media only screen and (max-width: 835px) {
    .recent-cards {
        display: block;
        width: 100%;
        padding: 9px 9px;
        box-sizing: border-box;
        justify-content: normal;
    }

    .recent-card {
        width: 100%;
        margin: 15px 0;
        max-width: none;
    }
}


@media only screen and (max-width: 590px) {
    .home-mid-section-divider {
        background-position: right center;

    }

    .home-mid-section-divider::after {
        background-image: linear-gradient(62deg, var(--orange) 60%, transparent 100%);
    }

    .home-mid-section-veriage-container {
        left: 0;
    }

    .recent-card-text-wrapper {
        padding: 0 1px;
    }

    .recent-title {
        font-size: 11px;
        font-weight: normal;
    }

    .recent-description {
        font-size: 9px;
    }
}