.services-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    transition: ease all .5s;
}

.services-card {
    width: 30%;
    margin: 48px 19px 0 19px;
    height: 413px;
    overflow: hidden;
    transition: ease all .5s;
}

.services-card-title {
    font-family: 'poppins-regular';
    font-weight: bold;
    color: #000;
    font-size: 16px;
    display: block;
    margin: 14px 0 26px 0;
    position: relative;
}

.services-card-title::after {
    content: '';
    border-bottom: 4px solid var(--blue-bg);
    height: 5px;
    width: 49px;
    position: absolute;
    left: 0;
    top: 31px;
}

.services-card-verbiage {
    color: var(--verbiage-font);
    font-size: 13px;
    margin-bottom: 21px;
    display: block;
    line-height: 1.9;
    letter-spacing: .3px;
}

.services-card-image {
    display: block;
    width: 100%;
    height: 199px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.services-card-image.apartment {
    background-image: url('../../images/interiorCleaning.jpg');
}

.services-card-image.movein {
    background-image: url('../../images/movein.jpg');
}

.services-card-image.commercial {
    background-image: url('../../images/commercial.jpg');
}

.services-card-image.post-party {
    background-image: url('../../images/party.jpg');
}

.services-card-image.unanticipated {
    background-image: url('../../images/damage.jpg');
}

.services-card-image.street {
    background-image: url('../../images/street.jpg');
}

.services-card-image.laundry-room {
    background-image: url('../../images/laundryRoom.jpg');
}

.services-card-image.lighting {
    background-image: url('../../images/bulb-changers.jpg');
}

.services-card-image.office {
    background-image: url('../../images/office-furniture.jpg');
}

@media only screen and (max-width: 1156px) {
    .services-card-wrapper {
        justify-content: space-between;
    }

    .services-card {
        width: 28%;
    }
}

@media only screen and (max-width: 835px) {
    .services-card-wrapper {
        display: block;
        width: 100%;
        padding: 9px 9px;
        box-sizing: border-box;
        justify-content: normal;
    }

    .services-card {
        width: 100%;
        margin: 0;
    }
}