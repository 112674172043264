.carousel-image-landing {
    background-image: url('../../images/carousel0.gif');
}

.carousel-image-home {
    background-image: url('../../images/carousel1.jpg');
}

.carousel-image-about {
    background-image: url('../../images/carousel5.jpg');
}

.carousel-image-maintenance {
    background-image: url('../../images/cleaning.jpg');
}

.carousel-image-office {
    background-image: url('../../images/office.jpg');
}

.carousel-image-garbage {
    background-image: url('../../images/garbage.jpg');
}

.carousel-image-snow {
    background-image: url('../../images/snow.jpg');
}

.carousel-image-construction {
    background-image: url('../../images/carousel2.jpg');
}

.carousel-image-post {
    background-image: url('../../images/renovation.jpg');
}

.carousel-container {
    position: relative;
}

.carousel-container::after {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
    height: 87vh;
    width: 100%;
    z-index: -20;
}

.slide-wrapper {
    display: none;
}

.slide-wrapper.active {
    display: block;
}

.slide-img {
    position: fixed;
    z-index: -11;
    height: 87vh;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .6;
    background-position: top 0 center;
}

.slide-img::before {
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
    z-index: -10;
    height: 87vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.slide-img video {
    position: relative;
    z-index: -11;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.slide-wrapper.active .slide-img {
    opacity: 1;
    -webkit-transition: opacity .825s ease-in-out;
    -moz-transition: opacity .825s ease-in-out;
    -o-transition: opacity .825s ease-in-out;
    transition: opacity .825s ease-in-out;
}

.carousel-arrow {
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, .3);
    cursor: pointer;
    top: 45%;
    color: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 4px 0px;
    text-align: center;
    box-sizing: border-box;
    font-size: 26px;
}

.carousel-arrow.right {
    right: 34px;
}

.carousel-arrow.left {
    left: 34px;
}

.carousel-body {
    position: relative;
}

@media only screen and (max-width: 930px) {
    .carousel-arrow {
        top: 77%
    }

    .slide-img video {
        position: fixed;
    }
}